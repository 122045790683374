<template>
  <div class="container">
    <Section-Header
      :title="'熱門展覽'"
      :subtitle="'備受大眾喜愛的展覽主題'"
      :link="'/exhibs'"
    />
    <div class="">
      <swiper
        :modules="modules"
        class="h-full"
        :style="{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }"
        :slidesPerView="$state.isMobile ? 1 : 4"
        :spaceBetween="$state.isMobile ? 0 : 60"
        :navigation="true"
      >
        <swiper-slide v-for="row in rows" :key="row.title">
          <Card
            :link="`/exhib/${row.id}/${row.slug}`"
            :title="row.title"
            :cover="row.banner"
            :author="row.author"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import ServiceExhib from '@/services/Exhib';
import Card from '@/pages/index/components/list/CardExhib.vue';

import SectionHeader from './_SectionHeader.vue';
export default {
  components: {
    SectionHeader,
    Card,
    Swiper,
    SwiperSlide,
  },

  setup() {
    const rows = ref([]);
    let loading = ref(false);
    const fetcher = () => {
      loading.value = true;
      ServiceExhib.list({
        hot: 1,
        page: 1,
        limit: 4,
      })
        .then((res) => {
          if (res.code === 1) {
            const _res = res.data ? res.data : res;
            rows.value.push(..._res.subset);
          }
        })
        .finally((res) => {
          loading.value = false;
        });
    };

    fetcher();

    return {
      modules: [Navigation, Pagination],
      rows,
    };
  },
};
</script>
