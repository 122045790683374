<template>
  <div
    class="flex flex-col cursor-pointer relative mb-6 group"
    @click="to(link)"
  >
    <el-image
      class="border border-gray-200"
      :src="$filters.uploadUrl(cover, 'md')"
      lazy
    >
    </el-image>
    <div class="mt-4">
      <h4 class="font-bold text-2xl" v-highlight="keyword">{{ title }}</h4>
      <p class="text-sm mt-1">策展人 : {{ author }}</p>
    </div>
  </div>
</template>

<script>
import { ElImage } from 'element-plus';
export default {
  components: { ElImage },
  props: {
    keyword: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    cover: {
      type: String,
    },
    author: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  setup(props) {
    const to = (link) => {
      window.location.href = link;
    };
    return { to };
  },
};
</script>
