import Axios from './http';

export default class single {
  static async single(params) {
    return Axios('/post/single', {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      return res;
    });
  }
}
