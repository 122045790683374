<template>
  <div class="flex items-end mb-6">
    <div>
      <h2 class="text-[2.6rem] tracking-widest font-bold">{{ title }}</h2>
      <p class="font-bold text-lg mt-1">{{ subtitle }}</p>
    </div>
    <div class="action ml-auto">
      <router-link class="link ml-auto" :to="link">看所有展覽</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    link: {
      type: String,
    },
  },
};
</script>

<style></style>
