<template>
  <div>
    <Banner></Banner>
    <Latest class="mt-20"></Latest>
    <Hot class="mt-20"></Hot>
    <About class="mt-20"></About>
  </div>
</template>

<script>
import Banner from './_Banner.vue';
import Hot from './_Hot.vue';

import Latest from './_Latest.vue';
import About from './_About.vue';
export default {
  components: { Banner, Hot, Latest, About },
  setup() {
    return {};
  },
};
</script>

<style></style>
