import Axios from './http';

export default class Resource {
  /**
   * @description 查询邀請函列表
   * @return {HttpResponse} result
   */
  static async load(params) {
    let url = '';
    return Axios('/resource/load', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
