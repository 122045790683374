<template>
  <div class="bg-gray-600 text-white py-20">
    <div class="container lg:px-64 flex flex-col items-center">
      <h2 class="font-bold text-3xl lg:text-4xl text-center tracking-widest">
        {{ row.title }}
      </h2>
      <div class="wysiwyg" v-html="row.content"></div>
    </div>
  </div>
</template>
<script>
// import { ref } from 'vue';
import { reactive } from 'vue';
import PostService from '@/services/Post';
export default {
  setup() {
    const row = reactive({
      title: '',
      content: '',
    });
    PostService.single({ slug: 'about' }).then((res) => {
      if (res.code !== 1) {
        return;
      }
      row.title = res.data.title;
      row.content = res.data.content;
    });
    return { row };
  },
};
</script>
<style lang="scss"></style>
