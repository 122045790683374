// import _ from 'lodash';
import createApp from '@/common';
import App from './App.vue';
import router from './router';
const app = createApp({});
import { ElLoading } from 'element-plus';
app.use(router);
app.use(ElLoading);
app.component('App', App);

app.mount('#app');
