<template>
  <div class="section mt-20">
    <div class="container">
      <Section-Header
        :title="'最新展覽'"
        :subtitle="'發現更多獨特策展觀點的魅力展覽'"
        :link="'/exhibs'"
      />
      <div class="flex flex-col">
        <div
          class="
            flex flex-wrap
            bg-gray-100
            first:mt-0
            mt-10
            lg:even:flex-row-reverse lg:items-stretch
          "
          v-for="row in rows"
          :key="row.title"
        >
          <div class="lg:w-1/2 w-full">
            <img
              :src="$filters.uploadUrl(row.poster || row.banner)"
              class="w-full"
              :alt="row.title"
            />
          </div>

          <div class="lg:w-1/2 w-full flex flex-col justify-center">
            <div class="p-10">
              <h4 class="font-bold text-3xl">{{ row.title }}</h4>
              <p class="mt-2">{{ row.author }}</p>
              <ElButton
                @click="goto(`/exhib/${row.id}/${row.slug}`)"
                class="lg mt-6"
                type="primary"
                >線上看展</ElButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import SectionHeader from './_SectionHeader.vue';
import exhibService from '@/services/Exhib';
import { ElButton } from 'element-plus';
export default {
  components: { ElButton, SectionHeader },
  setup() {
    const rows = ref([]);
    let loading = ref(false);
    const fetcher = () => {
      loading.value = true;
      exhibService
        .list({
          page: 1,
          limit: 4,
        })
        .then((res) => {
          if (res.code === 1) {
            const _res = res.data ? res.data : res;
            rows.value.push(..._res.subset);
          }
        })
        .finally((res) => {
          loading.value = false;
        });
    };
    const goto = (url) => {
      window.location.href = url;
    };

    fetcher();

    return {
      rows,
      goto,
    };
  },
};
</script>
